/* colorlist {
  dark-blue:#4183c4
  gravitotheme:orange
}*/
:root{
  --theme-color:#ffa100 
}
html{
  -ms-overflow-style: none;
}
.App {
  text-align: center;
  
}
.terms a{
  color: var(--theme-color) !important
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-from-bottom{
  height: 385px;
  width: 86vw;
  position: fixed;
  bottom: -400px;
  margin-left: 7vw;
  transition: bottom 250ms linear;
  background-color: white;
  
    border-radius: 10px;
    padding: 8px;
    overflow: scroll
}
.menu-from-bottom.bottom-menu-active{
  
  bottom:64px;
  z-index: 999
}
/* .header-line-2 .header-title {
  font-size: 28px;
  padding: 0px 20px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 0px;
  margin-top: 10px!important;
  text-align: left;
  transition: all 250ms ease;
  color: #000000;
} */
/* .header-line-2 {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: #FFFFFF;
  height: 90px;
  z-index: 99;
  border-bottom: solid 1px rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  align-content: center;
} */

.new-hider{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 200vh;
  filter: blur(15px);
  z-index: 999;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0.87);
  transition: all 250ms linear;
  

}
.color-white-custom{
  background-color: white
}
.active-menu:hover{
  color:darkred
}
.align-items-center{
  display: flex;
    align-items: center;
    height: 40px;
    color:black !important
}
.align-items-center > span{
  padding-left:10px !important
}
.background-Image-201{
  background-image: url('../src/assets/images/pictures/18l.jpg');
}
.background-Image-202{
  background-image: url('../src/assets/images/pictures/6m.jpg')
}
.background-Image-203{
  background-image: url('../src/assets/images/pictures/15m.jpg')
}
.cover-content-center-full {
  top: 70%;
  left: 0px!important;
  margin-left: 0px!important;
  padding: 0px 20px;
}
.owl-carousel {
  display: block !important;
  width: 100%;
  z-index: 1;
}
.login-page-div{
  height:100vh;
  background-image: url('../src/assets/images/pictures_vertical/bg0.jpg');
  background-size: cover;
  background-repeat: no-repeat
}
.login-content-div{
  margin: auto;
    top: 10vh;
}
.menu-hide {
  position: absolute;
  right: -7px;
  top: -21px;
  height: 90px;
  line-height: 90px;
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #BF263C;
}
.menu-title{
  height: 50px;
  padding: 8px 20px;
  border-bottom: solid 1px rgba(0,0,0,0.05);
}
.setting-from-bottom{
  height: 230px;
  width: 86vw;
  position: fixed;
  bottom: -400px;
  margin-left: 7vw;
  transition: bottom 250ms ease-in-out;
  background-color: white;
  
    border-radius: 10px;
    padding: 8px;
    overflow: scroll
}
.setting-from-bottom.bottom-setting-active{
  bottom:64px;
  z-index: 999
}
.setting-hide {
  position: absolute;
  right: -7px;
  top: -21px;
  height: 90px;
  line-height: 90px;
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #BF263C;
}
.setting-title{
  height: 50px;
  padding: 8px 20px;
  border-bottom: solid 1px rgba(0,0,0,0.05);
}
.setting-title h1 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: -0.5px;
}
.mr-15{
  margin-right:15px
}
.align-items-lang{
  display: flex;
    align-items: center;
    height: 30px;
    font-size: 15px;
    font-weight: 600;
    color: black;
}
.feedback-from-bottom{
  height: auto;
  width: 80vw;
  position: fixed;
  bottom: -400px;
  margin-left: 10vw !important;
  transition: bottom 250ms ease-in-out;
  background-color: white;
  
    border-radius: 10px;
    padding: 8px;
    overflow: scroll
}
.feedback-from-bottom.bottom-feedback-active{
  bottom:231px;
  z-index: 999
}
.deco-4 {
  height: 1px;
  background-color:#4FC1E9 ;
  margin-bottom: 10px;
}
.submit-button{
    background-color: var(--theme-color)!important;
    color: white !important;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 35px;
}
.right-float{
  float: right;
}
.color-highlight{
  color:var(--theme-color) !important
}
.page-login {
  z-index: 1000;
  min-width: 270px;
  max-width: 300px;
  margin: 0px auto 30px auto;
}
.feedback-hide {
  position: absolute;
  right: -7px;
  top: -25px;
  height: 90px;
  line-height: 90px;
  width: 80px;
  text-align: center;
  font-size: 20px;
  color: #BF263C;
}
.header-img{
  width: auto;
  height: 116px;
  margin-bottom:15px
}
.terms-conditions-header{
  display: flex;
    flex-direction: row;
    align-items: initial;
    background-color: orange;
    padding: 15px;
    color: white;
    text-align: center;
}
.card-custom{
  margin: auto !important;
  margin-top: 20px !important;
  margin-bottom:40px !important;
  width: 100% !important;
  padding:10px !important;
  text-align: left !important
}
.regularbold {
  font-weight: 500!important;
  margin-top: 2px;
}
ol ol {
  list-style-type:circle;
}
.bold {
  font-weight: 600!important;
  color: black;
}
.profile-div{
  position: absolute;
    top: 14vh;
    width: 100vw;
    margin-bottom:10vh !important
}
.profile-input{
  height:50px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.ml-15{
  
  width: 75%;
    padding-bottom: 4px;
    border-bottom: solid 1px orange;
    background:transparent
}
.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  position:fixed;
  display: flex;
  opacity: .9;
  height: 120vh;
  /* background-color: #4A89DC; */
  /* z-index: 9999999; */
  top: -95px !important;
}
.ui.inline.dropdown>.text {
  font-weight: 400 !important;
}
.ui.dimmer {
  display: none;
  position: absolute;
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgb(24, 23, 23) !important;
  opacity: 0;
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: .5s;
  transition: background-color .5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}
.select-domain{
  font-size: 17px;
    font-weight: 400;
    letter-spacing: 0px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 10px !important
}
.ui.fluid.dropdown {
  display: block !important;
  width: 100% !important;
  /* border-radius: 10px !important; */
  
  margin-left: 0%;
  margin-top: 10px;
  min-width: 0;
}
.date-header
  {
    padding: 13px 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--theme-color);
    font-size: 10px;
    letter-spacing: 0.4px;
}
.header-logo-app .header-logo-title {
  text-align: left;
  left: 30px;
  padding-left: 10px;
  font-size: 19px;
}
.header-line-1 .header-icon {
  position: absolute;
  top: 0px;
  width: 45px;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
}
.Profile-header{
  background-color: orange;
    color: white;
    height: auto;
    padding: 12px;
    border-radius: 10px;
    margin-left: -3px;
    margin-bottom: 10px;
}








.bg-night-dark {
  background-color: #434A54!important;
  color: #FFFFFF;
  text-align: center;
}
.add-login-div{
  margin-top:0px;
  display:flex;
  align-content:initial;

}
.height-36{
  height:36px
}
.footer-buttons{
  display:flex;
  justify-content: left;
  justify-items: left;
}
.mr-top-15{
  margin-top: 15px
}
.ui.dimmer .ui.loader {
  color:white !important;
  position: fixed;
  top: 45vh;
  border-color: var(--theme-color) !important
}
table {
  background-color: #f6f6f6;
  font-size: 12px;
  margin-bottom: 10px !important;
}
.display-toggle{
  display:block
}
.color-main{
  color:black !important
}
.ui.dimmer .ui.loader:after {
  border-color: var(--theme-color) transparent transparent !important;
}

/* .app-background{
  background-color:#ffa100 !important;
} */
.transparent{
  background: transparent !important
}
.mr-top-10{
  margin-top:10px ;
  margin-bottom:10px
} 
.full-width {
 
  width: 100%;
  padding-bottom: 4px;
  border-bottom: solid 1px orange;
  background: transparent;
}
.full-width.ui.input {
  font-size: 1em;
  margin-left: 0px !important;
  width: 100%
}

/* .app-contrast-color{
  color:white !important
} */
.domain-search{
  color:orange;
  width:50% !important
}
.domain-search>.text{
  margin-right:10px;
  color:orange
}
.ui.inline.dropdown>.text{
  color:var(--theme-color)!important
}
.consent-search{
  position: absolute !important;
    right: 15px;
}
.consent-search>.text{
  margin-right:10px;
  color:var(--theme-color)
}

.border-radius{
  border-radius: 15px !important
}
.card {
  padding: 30px 20px;
  margin: 15px 2px !important;
  border-radius: 15px;
  box-shadow: 0 0 15px 4px rgba(0,0,0,0.1)!important;
}
.ui.card>:last-child, .ui.cards>.card>:last-child {
  border-radius: 15px !important;
}
.consent-item{
  width: auto;
  margin-bottom:10px;
  margin-right:10px !important;
  background-color: orange;
  color: white;

  border-radius: 10px;
  padding: 10px;
}
.margin-auto{
  margin:auto !important;
  margin-top: 10px !important;
}
.no-padding{
  padding:0px !important
}
.toggle-button{
  position: absolute;
    right: 15px;
    top: 12px;
}
.select-box-1 {
  margin-top: 0px !important;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,0.1);
  padding-top: 5px;
  font-size: 12px;
}
.floating-btn {
  position: fixed;
  bottom: 70px;
  right: 7px;
  width: 46px;
  z-index: 900;
}
.floating-btn i{
  font-size: 1.4em !important
}
.menu-list li span {
  font-size: 14px;
  padding-left: 32px;
  font-weight: 400 !important;
}



/* .container, .container-fullscreen {
  margin-bottom: 5px;
  display: block;
}
.content-padding {
  padding: 20px 0px;
} */
.decoration {
  height: 1.2px;
  display: block;
  margin-bottom: 15px;
  clear: both!important;
  background-color: rgba(0,0,0,0.05);
}

.hr-style{
 
  margin:10px 0px 10px 0px;
  border-top: 1px solid grey
}
.color-white>a{
  color:white
}
.floating-label{
  
    width: 60px;
    text-align: center;
    position: absolute;
    right: -10px;
    bottom: -5px;
    height: 30px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 5px;
}
.gallery-wide h1, .gallery-wide strong {
  position: absolute;
  bottom: -10px;
  height: 42px;
  font-size: 17px;
  line-height: 27px;
  padding: 0px 15px;
  z-index: 10;
  color: #c9c9c9;
  text-align: center;
}
.name-header
  {
    margin-bottom:10px;
    line-height: 45px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    
}
.link-list a {
  margin-left: 0px;
  line-height: 45px;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  border-bottom: solid 1px white;
}
.link-list a i {
  font-size: 20px;
  position: absolute;
  height: 50px;
  width: 25px;
  line-height: 45px;
  left: 82vw !important;
  top: 4px;
  /* right: 19px; */
  text-align: center;
}
.show{
  display:block
}
.hide{
  display: none
}
.icon-xs i {
  width: 35px!important;
  height: 35px!important;
  line-height: 35px!important;
  font-size: 14px;
  color: white !important;
}
.active-color{
  color:var(--theme-color)!important
}
th {
  text-align: center;
}
strong.regular{
  color:white !important
}
.display-block{
  display: block
}
.ui.radio.checkbox {
  min-height: 15px;
  margin-bottom: 0px;
}
b, strong {
  font-weight: bolder;
  /* font-style: italic; */
  color: var(--theme-color);
}
.rv-xy-plot__series--label {
  fill: grey;
  font-size: 10px;
  
}
.node {
  cursor: pointer;
}

.node:hover {
  stroke: #000;
  stroke-width: 1.5px;
}

.node--leaf {
  fill: white;
}

.label {
  font: 11px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-anchor: middle;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
}

.label,
.node--root,
.node--leaf {
  
}
.segment-box{
  height: 45px;
  width: 150px;
  margin-top: 10px;
  margin-right:5px
}
.chips-large a {
  font-size: 10px;
  padding: 0px 14px 0px 34px;
  border-radius: 20px;
  margin-right: 16px;
  margin-bottom: 5px;
  word-wrap: break-word
}
.segment-box-selected{
  height: 100px;
  width: 150px;
  margin-top: 10px;
  margin-right:5px
}
.i-slider{
  position: absolute;
  
  bottom: 8px;
  left: 20px;
  background-color: white;
  width: 74% !important;
}
.caption-style-4 {
  position: absolute;
  z-index: 20;
  left: 0px;
  right: 0px;
  top: -6px;
  bottom: 0px;
  padding: 15px 20px 15px 20px;
}
.op-cls{
  z-index: 1 !important;
}
.ui.fluid.dropdown {
  display: block !important;
  width: 100% !important;

  margin-left: 0%;
  margin-top: 10px;
  min-width: 0;
}

.custom-dd{
  border: 0px !important;
  padding-left: 0px !important;
  border-bottom: 1px solid #CACFD2  !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 0px !important;
  font-size: 12px !important;
}
.add-own{
  background: white;
  font-size: 12px !important;
  border: 1px solid darkgrey;
  border-right:0px;
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
  padding: 0px 0px 0px 14px;
  min-width: 240px;
  
}
.add-btn{
  margin: 0px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.display-for-web{
  display:none
}
.chart-div{
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.responsive-image1 {
  
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.dash-div {
  position: absolute;
  top: -3vh;
  width: 100vw;
  margin-bottom: 10vh !important;
}
.bg-img{
  background-image: url('../src/assets/images/pictures_vertical/bg2.jpg')
}
.svg{
  width:80vw;
  height: 80vw;
}
.theme-color{
  color:var(--theme-color)!important
}
.bg-theme-color{
  color: white !important;
  background-color: var(--theme-color)!important
}
.checkbox-div{
  display:flex
}
.delete-hide {
  position: relative;
  right: 5px;
  top: -25px;
  height: 36px;
  line-height: 90px;
  
  text-align: center;
  font-size: 20px;
  color: #BF263C;
}
li{
  cursor: pointer !important ;
}
.chips-large a {
  font-size: 10px;
  padding: 0px 14px 0px 34px;
  border-radius: 20px;
  margin-right: 16px;
  margin-bottom: 5px;
  word-wrap: break-word;
  height: 50px;
}
.responsive-table{
  width: 90vw;
}
.responsive-top-margin{
  margin-top: 45px;
}
.responsive-svg-container{
  width:100vw;
  overflow: scroll
  
}


/* responsive*/
@media screen and (min-width:750px){
  .responsive-top-margin{
    margin-top: 70px;
  }
  .responsive-table{
    width: 95vw;
  }
  .responsive-search{
    width:30vw !important;
  }
  .feedback-from-bottom{
    margin-left: 34vw
  }
  .display-toggle{
    display: none
  }
  .card-custom{
    margin: auto !important;
    margin-top: 20px !important;
    margin-bottom:40px !important;
    width: 100% !important;
    padding:10px 70px 10px 30px !important;
    text-align: left !important
  }
  .display-for-web{
    display: block
  }
  .menu-from-bottom{
    height: 570px;
    width: 86vw;
    position: fixed;
    bottom: -600px;
    margin-left: 7vw;
    transition: bottom 250ms linear;
    background-color: white;
    
      border-radius: 10px;
      padding: 8px;
      overflow: scroll
  }
  /* .gallery-wide h1, .gallery-wide strong {
    position: absolute;
    bottom: -19px;
    height: 50px;
    font-size: 17px;
    line-height: 50px;
    padding: 0px 15px;
    z-index: 10;
    color: #c9c9c9;
}
strong.regular {
  color: #4CAF50 !important;
}
.gallery-wide img {
  margin-bottom: 0px;
  display: none;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
  min-height: 150px;
} */
.responsive-image {
  height: 0px;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.gallery-wide a {
  position: relative;
  height: 100px;
}
/* strong.regular {
  color: black !important;
} */
.overlay-gradient {
  background: transparent
}
.gallery-wide h1, .gallery-wide strong {
  position: absolute;
  bottom: -10px;
  height: 50px;
  font-size: 17px;
  line-height: 50px;
  padding: 0px;
  z-index: 10;
  color: #c9c9c9;
  text-align: center;
}


}
.justify-center-link{
  display: flex;
    justify-content: center;
    color: #4183c4;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: small;
    cursor: pointer;


}
.node circle {
  fill: #fff;
  stroke: grey;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}